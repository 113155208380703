<template>
    <div class="container">
        <!-- head -->
        <div class="head">
            <div class="button">
                <el-button type="primary" @click="handleIncrement">添加退货地址</el-button>
            </div>
            <!-- <div class="search">
          <span class="search_label">角色名称：</span><el-input v-model="searchInfo.role_name" clearable @clear="handleClear"
            class="search_input" placeholder="输入角色名称搜索" prefix-icon="el-icon-search">
          </el-input><button class="search_btn" @click="handleSearch">搜索</button>
        </div> -->
        </div>
        <!-- table -->
        <div class="table">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="name" label="姓名">
                </el-table-column>
                <el-table-column prop="mobile" label="联系电话">
                </el-table-column>
                <el-table-column prop="address" label="地址" min-width="180">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="oper" @click="handleModify(scope.row)">编辑</span>
                        <el-divider direction="vertical"></el-divider>
                        <span class="oper" @click="handleDelete(scope.row)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- dialog -->
        <el-dialog :title="title" class="balancetc" :visible.sync="addressDialog" width="600px"
            :close-on-click-modal="false" :show-close="false">
            <div class="rbalancebox">
                <el-form :model="addressForm" ref="addressForm" label-width="80px" :rules="rules">
                    <ul>
                        <li class="clearfloat balanceformitem">
                            <el-form-item label="姓名" prop="name">
                                <div class="orderfirstinput">
                                    <el-input placeholder="请输入姓名" v-model="addressForm.name"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                        <li class="clearfloat balanceformitem">
                            <el-form-item label="手机号" prop="mobile">
                                <div class="orderfirstinput">
                                    <el-input placeholder="请输入手机号" v-model="addressForm.mobile"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                        <li class="clearfloat comments">
                            <el-form-item label="退货地址" prop="address">
                                <div class="deliveryright">
                                    <el-input type="textarea" v-model="addressForm.address"
                                        placeholder="请输入退货地址"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                    </ul>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button @click="handleSubmit" type="primary">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { Message } from 'element-ui';
import common from '../../../../common/common';

export default {
    data() {
        return {
            tableData: [],//tableData
            addressForm: {
                name: "",
                mobile: "",
                address: "",
            },//添加地址表单对象
            addressDialog: false,
            title: "",
            rules: {
                name: [{ required: true, message: "请输入姓名!", trigger: 'blur' }],
                mobile: [{ required: true, validator: this.mobileValid, trigger: 'blur' }],
                address: [{ required: true, message: "请输入退货地址!", trigger: 'blur' }],
            }
        }
    },
    created() {
        this.getRoleList();
    },
    methods: {
        mobileValid(rules, value, callback) {
            if (value == "") {
                callback(new Error("请输入手机号码!"));
            }
            const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
            if (!reg.test(value)) {
                callback(new Error("请输入正确的手机号码!"));
            }
            callback();
        },
        // 添加退货地址
        handleIncrement() {
            this.title = "添加退货地址";
            this.addressDialog = true;
        },
        // 获取退货地址列表
        async getRoleList() {
            const { data } = await this.$get(this.$apis.refundAddressApi);
            this.$set(this, 'tableData', data);
        },
        // 编辑
        handleModify({ id }) {
            this.$get(`${this.$apis.refundAddressApi}/${id}`).then(res => {
                if (res.code != 200) {
                    return Message.error(res.message)
                }
                this.$nextTick(() => {
                    this.$set(this, "addressForm", res.data);
                    this.title = "编辑退货地址";
                    this.addressDialog = true;
                })
            })
        },
        // 删除
        handleDelete(row) {
            this.$confirm(`确定要将该地址永久删除吗?`, "警告", {
                type: "warning"
            }).then(() => {
                this.$delete(`${this.$apis.refundAddressApi}/${row.id}`).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()]({
                        message: `删除地址${res.code == 200 ? '成功!' : res.message}`
                    })
                    this.getRoleList();
                })
            }).catch(err => {
                console.log(err);
                // Message[(() => err == 'cancel' ? 'info' : 'error')()]({
                //     message: `${err == 'cancel' ? '已取消删除!' : error}`
                // })
            })
        },
        handleCancel() {
            this.$refs['addressForm']['resetFields']();
            this.addressDialog = false;
        },
        // 添加
        handleSubmit() {
            const { id } = this.addressForm
            if (!id) {
                this.$post(`${this.$apis.refundAddressApi}`, this.addressForm).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()]({
                        message: `添加退货地址${res.code == 200 ? '成功!' : res.message}`
                    })
                    res.code == 200 && this.handleCancel()
                    this.getRoleList();
                })
            } else {
                this.$put(`${this.$apis.refundAddressApi}/${id}`, this.addressForm).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()]({
                        message: `编辑退货地址${res.code == 200 ? '成功!' : res.message}`
                    })
                    res.code == 200 && this.handleCancel()
                    res.code == 200 && delete this.addressForm['id']
                    this.getRoleList();
                })
            }

        }
    }
}

</script>
  
<style scoped lang="less">
.container {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;

    .head {
        width: 100%;
        // min-height: 58px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        .button {
            // width: 90px;
            height: 38px;
            border-radius: 4px;

            .btn_oper {
                padding: 9px 17px;
                box-sizing: border-box;
                border: none;
                outline: none;
                background: var(--fontColor);
                font-size: 14px;
                font-weight: 400;
                border-radius: 4px;
                color: #FFFFFF !important;
            }
        }

        .search {
            .search_label {
                display: inline-block;
                width: 70px;
                height: 16px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 16px;
            }

            .search_input {
                width: 240px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 0 0 4px;

                &/deep/.el-input__inner {
                    height: 100%;
                    border-radius: 4px 0 0 4px;

                    &::placeholder {
                        // width: 156px;
                        line-height: 16px;
                        height: 16px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #CCCCCC;
                        line-height: 16px;
                    }
                }
            }

            .search_btn {
                width: 60px;
                height: 38px;
                background-color: var(--fontColor);
                border-radius: 0 4px 4px 0;
                outline: none;
                border: none;
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF !important;
            }
        }
    }

    .table {
        & /deep/.el-table__header {
            height: 50px !important;
            background: #F9F9FC !important;

            & tr th {
                background: #F9F9FC !important;

                & .cell {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    padding: 0 16px;
                }
            }
        }

        & /deep/.el-table__body-wrapper.is-scrolling-none table tbody>tr {
            // height: 76px !important;

            & .cell {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding: 0 16px;
            }
        }

        & .el-divider--vertical {
            margin: 0 8px
        }

        & .oper {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: var(--fontColor);
            cursor: pointer;
        }
    }

    .table_foot {
        margin-top: 20px;
    }

    .rbalancebox {
        padding: 20px 40px 20px 20px;
        box-sizing: border-box;

        & ul li {
            margin-bottom: 0;
        }
    }

    .balanceformitem {


        // display: flex;
        // align-items: center;
        & .orderfirstinput,
        .comments {
            width: 100%;

            & /deep/.el-form-item {
                width: 100%;

                & .el-input__inner {
                    width: 100%;
                }
            }

        }
    }

    .deliveryright {
        width: 100% !important;
    }

    .dialog-footer .addressqr:hover {
        color: #fff !important;
    }

}
</style>